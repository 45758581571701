const PartidasAction = {
	id: 'documentosAction',
	selectionType: 'single',
	label: 'action.documentoaduaneropartidas',
	functionality: 'READ_PARTIDADOCADU',
	checkAvailability: function(/*vue, registries*/) {
		return true;
	},
	runAction: function(vue, action, model /*, registries*/) {
		//const nbspObjectPk = utilActions.getPkFromModel(model, registries[0]);
		var nbspObjectPk = { iddocumento: model[0].iddocumento };
		const pkStr = JSON.stringify(nbspObjectPk);
		const pk64 = this.$puiUtils.utf8ToB64(pkStr);
		model[0].tabFromAction = 'partidas';

		const thisModel = this.$store.getters.getModelByName(action.name);
		thisModel.tabFromAction = 'partidas';

		const params = {
			router: this.$router,
			url: `${action.name}/read/${pk64}/`,
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

export default {
	gridactions: [PartidasAction],
	formactions: [PartidasAction]
};
